import React from "react";
import type { AppProps /*, AppContext */ } from 'next/app'
import "../node_modules/normalize.css/normalize.css";
import '@fontsource/roboto'
import '../styles/globals.css'
import '../styles/style.css'
import styled, { ThemeProvider } from 'styled-components';
import NoSsr from '@material-ui/core/NoSsr';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  darken,
} from '@material-ui/core/styles';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#f9671e',
    },
    secondary: {
      main: '#7cc8e5',
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <NoSsr>
      <MuiThemeProvider theme={customTheme}>
        <ThemeProvider theme={customTheme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </MuiThemeProvider>
    </NoSsr>
  )
}

export default MyApp
